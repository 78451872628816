<template>
  <!-- 诊所下拉列表 -->
  <el-select
    v-model="val"
    @change="onChange"
    clearable
    filterable
    :placeholder="lang_data == 1 ? '请选择' : 'Please Enter'"
  >
    <!-- :filter-method="getList"
             @visible-change="getList" -->
    <el-option value="" label="全部" v-if="isAll"></el-option>
    <el-option
      v-for="item in list"
      :value="item.code"
      :label="item.name"
      :key="item.id"
    ></el-option>
  </el-select>
</template>
<script>
export default {
  props: {
    value: {
      type: [Number, String],
    },
    isAll: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      lang_data: localStorage.lang_data || 1,
      val: "", //
      list: [],
    };
  },
  mounted() {
    this.val = this.value;
    this.getList();
  },
  methods: {
    onChange(val) {
      this.$emit("input", val);
      this.$emit("change", val);
    },
    getList() {
      let params = {};
      this.$axios.post("/setting/express_company/getlist", params).then((res) => {
        if (res.code == 1) {
          this.list = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
  watch: {
    value(v, ov) {
      if (v != ov) {
        this.val = this.value;
      }
    },
  },
};
</script>
